import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import ReportOverview from 'modules/payrollProcessorHub/components/PayrollModal/reports/ReportOverview';
import { IReportOverviewItem } from 'modules/payrollProcessorHub/store/model';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { usePreAndPostInitializeReportStyles } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/styles';
import {
    useSettingsFormStyles,
} from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { usePayrollReportStyle } from 'modules/payrollProcessorHub/components/PayrollModal/reports/PayrollStyle';
import clsx from 'clsx';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';

export interface ICommonPrePostInitializeReportPureProps<TReportEntry>{
    buttonTitle: string,
    title: string,
    rows: TReportEntry[],
    cells: ICellInfo<TReportEntry>[],
    getRowId: (row: TReportEntry) => string,
    overviewItems: IReportOverviewItem[],
    buttonHandler: () => void,
    isButtonLoading?: boolean,
    backButtonHandler?: () => void,
    hideBackButton?: boolean,
    additionalOverview?: React.ReactNode,
    additionalButton?: React.ReactNode,
    hideTable?: boolean,
}

export function CommonPrePostInitializeReportPure<TReportEntry>(
    {
        title, backButtonHandler, rows, cells, overviewItems, getRowId,
        buttonTitle, buttonHandler, isButtonLoading, hideBackButton,
        additionalOverview = null, additionalButton = null, hideTable = false,
    }: ICommonPrePostInitializeReportPureProps<TReportEntry>,
) {
    const reportOverviewBlocks: IReportOverviewItem[][] = useMemo(() => {
        return [
            overviewItems,
        ];
    }, [overviewItems]);
    const classes = usePreAndPostInitializeReportStyles();
    const tableClasses = useDefaultTableStyles();
    const modalClasses = useSettingsFormStyles();
    const overviewClasses = usePayrollReportStyle();

    return (
        <Box>
            <Box className={classes.mainContent}>
                <Typography variant="h2" align={'center'}>{title}</Typography>
                {!hideTable && (
                    <Box
                        margin={2}
                        marginBottom={4}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <ReportOverview
                            blocks={reportOverviewBlocks}
                            classes={
                                {
                                    column: overviewClasses.SummaryInfoColumn,
                                    row: overviewClasses.SummaryInfoRow,
                                    value: overviewClasses.SummaryInfoValue,
                                }
                            }
                        />
                        {additionalOverview}
                    </Box>
                )}
                {!hideTable && (
                    <GridTable
                        rowData={rows}
                        getKey={getRowId}
                        getRowId={getRowId}
                        cells={cells}
                        isLoading={false}
                        headerCellClassName={clsx(tableClasses.headCell, classes.tableHeadCell)}
                        bodyCellClassName={tableClasses.bodyCell}
                        className={tableClasses.tableBorder}
                        stickyHeader
                    />
                )}
                {hideTable && (
                    <Box>The imported subassignments are creating asynchronously. Refresh the search if needed.</Box>
                )}
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                mt={5}
            >
                <Box>
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={modalClasses.button}
                        onClick={buttonHandler}
                        isLoading={isButtonLoading}>
                        { buttonTitle }
                    </ButtonWithLoader>
                    {
                        !hideBackButton && (
                            <Button
                                variant="outlined"
                                color="primary"
                                className={modalClasses.button}
                                onClick={backButtonHandler}
                            >
                                Back
                            </Button>
                        )
                    }
                </Box>
                {additionalButton && (
                    <Box mt={4}>
                        {additionalButton}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
