import React from 'react';
import { BulkCreationReportV2 } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/BulkCreationReportV2';
import { useBulkPostCreateOverviewItems } from 'modules/subassignmentManagement/components/CreateSubAssignment/CreationReportV2/hooks';

export interface ICreationPostinitializeReportProps{
    mainButtonHandler: () => void,
}

export function CreationPostInitializeReportV2({ mainButtonHandler }: ICreationPostinitializeReportProps){
    const overviewItems = useBulkPostCreateOverviewItems();

    return (
        <BulkCreationReportV2
            handleAction={mainButtonHandler}
            title="Post-Initialize Roll Up"
            buttonTitle="Ok"
            overviewItems={overviewItems}
            hideTable={true}
        />
    );
}
