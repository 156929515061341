import { selectCreationReportV2Rows } from 'modules/subassignmentManagement/components/CreateSubAssignment/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IReportOverviewItem } from 'modules/payrollProcessorHub/store/model';
import { CommonPrePostInitializeReportPure } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/CommonPrePostInitializeReport';
import { getBulkCreationReportCells } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/cells';

interface IBulkCreationReportV2Props {
    onBack?: () => void;
    title: string;
    handleAction: () => void;
    buttonTitle?: string;
    isLoading?: boolean;
    overviewItems: IReportOverviewItem[],
    additionalOverview?: React.ReactNode,
    additionalButton?: React.ReactNode,
    hideTable?: boolean,
}

export function BulkCreationReportV2({
    title = 'Pre-Initialize Roll Up',
    buttonTitle = 'Create',
    onBack,
    handleAction,
    isLoading,
    overviewItems,
    additionalOverview,
    additionalButton,
    hideTable,
} : IBulkCreationReportV2Props){
    const cells = getBulkCreationReportCells();
    const rows = useSelector(selectCreationReportV2Rows);
    return (
        <CommonPrePostInitializeReportPure
            title={title}
            buttonTitle={buttonTitle}
            buttonHandler={handleAction}
            isButtonLoading={isLoading}
            backButtonHandler={onBack}
            hideBackButton={!onBack}
            overviewItems={overviewItems}
            rows={rows}
            cells={cells}
            getRowId={row => row.employee}
            additionalOverview={additionalOverview}
            additionalButton={additionalButton}
            hideTable={hideTable}
        />
    );
}
