import {
    expenseApprovalInfinityTableSelectors,
    timeApprovalInfinityTableSelectors,
} from 'modules/sheetApproval/components/SheetApprovalInfinityTable/store/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { selectSheetGroupingEnabled } from 'store/entities/clients/selectors/configurationSelectors';
import {
    selectClientHasActivities, selectIsActivitiesLoaded,
} from 'store/entities/configuration/configurationSelectors';
import { selectCustomFieldValuesByIds } from 'store/entities/customFields/selectors';
import { IScope } from 'store/entities/scopes/models';
import { selectActivityScopes } from 'store/entities/scopes/selectors';
import { groupBy, union } from 'lodash-es';

export const useIsLoadingTimeAndExpense = () => {
    const isActivitiesLoaded = useSelector(selectIsActivitiesLoaded);
    const clientHasActivities = useSelector(selectClientHasActivities);
    return !clientHasActivities && !isActivitiesLoaded;
};

export const useShowTimeAndExpense = () => {
    const clientHasActivities = useSelector(selectClientHasActivities);
    return clientHasActivities;
};

export const useApprovalSelectors = (sheetType: EntryType) => {
    return sheetType === EntryType.TIME ? timeApprovalInfinityTableSelectors : expenseApprovalInfinityTableSelectors;
};

export const useIsEmployeeSheetGroupingEnabled = (): boolean => {
    return useSelector(selectSheetGroupingEnabled);
};

/***
 * Return all scopes filtered by selected custom field values
 * @param formCustomFieldValueIds
 */
export const useScopes = (formCustomFieldValueIds: Record<string, string> = {}): IScope[] => {
    const activityScopes = useSelector(selectActivityScopes);
    const customFieldValuesByIds = useSelector(selectCustomFieldValuesByIds);

    return useMemo(() => {
        return activityScopes.filter(scope => {
            const groupedScopeCustomFieldValues = groupBy(
                scope.custom_field_value_ids.map(id => customFieldValuesByIds[id]).filter(Boolean),
                value => value.custom_field_id,
            );

            const matches = scope.field_ids?.every(customFieldId => {
                const customFieldValueIds = groupedScopeCustomFieldValues[customFieldId]?.map(
                    customFieldValue => customFieldValue.id,
                );
                const matchResult = customFieldValueIds?.includes(formCustomFieldValueIds[customFieldId]);
                return matchResult;
            });
            if (!Object.keys(groupedScopeCustomFieldValues).includes('439591dd-6e51-49e6-8b75-148303c67076')) {
                return false;
            }
            return matches;
        });
    }, [customFieldValuesByIds, formCustomFieldValueIds, activityScopes]);
};

export const getActivityIdsByScopes = (scopes: IScope[]) => {
    return union(...scopes.map(scope => scope.activity_ids));
};
