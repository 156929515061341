import baseApi from 'shared/utils/baseApi';
import { clientConfigurationsService } from 'store/entities/configuration/configurationApi';
import {
    CustomFieldScopeRestrictions,
    IGetScopeCustomFieldRestrictionRequest,
    IGetScopeRequest, IGetScopesV2Request, IGetUsersMatchingUsersScopesRequest,
    IGroupedScopeCustomFieldValues,
    IGroupedScopeCustomFieldValuesRequest,
    IScope, IScopesActivitiesV2Result, IUserMatchItem, IUserMatchResponse,
    ScopeType,
} from 'store/entities/scopes/models';

const scopesRoute = 'scopes';

export const scopesApi = {
    async getScopes(requestPayload: IGetScopeRequest): Promise<IScope[]> {
        const { data } = await baseApi.get<{ scopes : IScope[] }>(
            `/${clientConfigurationsService}/${scopesRoute}`,
            {
                scope_types: requestPayload.scope_types || [ScopeType.SubassignmentManagement],
                client_ids: requestPayload.client_ids || [baseApi.clientId],
            },
        );
        return data.scopes;
    },
    async getScopesLightWeight(requestPayload: IGetScopeRequest): Promise<IScope[]> {
        const { data } = await baseApi.get<{ scopes : IScope[] }>(
            `/${clientConfigurationsService}-additional/${scopesRoute}/simplified`,
            {
                scope_types: requestPayload.scope_types || [ScopeType.SubassignmentManagement],
                client_ids: requestPayload.client_ids || [baseApi.clientId],
            },
        );
        return data.scopes;
    },
    async getScopeFull(requestPayload: string): Promise<IScope[]> {
        const { data } = await baseApi.get<{ scopes : IScope[] }>(
            `/${clientConfigurationsService}-additional/${scopesRoute}/${requestPayload}`,
        );
        return data.scopes;
    },
    async getUserCustomFieldRestrictions(
        requestPayload: IGetScopeCustomFieldRestrictionRequest,
    ): Promise<CustomFieldScopeRestrictions> {
        const { data } = await baseApi.get<{ grouped_scopes: CustomFieldScopeRestrictions }>(
            `/${clientConfigurationsService}/${scopesRoute}/grouped`,
            {
                scope_types: requestPayload.scope_types || [ScopeType.SubassignmentManagement],
                client_ids: requestPayload.client_ids || [baseApi.clientId],
                mine: requestPayload.mine,
            },
        );
        return data.grouped_scopes;
    },
    async getUserCustomFieldRestrictionsV2(
        requestPayload: IGroupedScopeCustomFieldValuesRequest,
    ): Promise<IGroupedScopeCustomFieldValues[]> {
        const { data } = await baseApi.get<{ grouped_scopes: IGroupedScopeCustomFieldValues[] }>(
            `/${clientConfigurationsService}/${scopesRoute}/grouped/v2`,
            requestPayload,
        );
        return data.grouped_scopes;
    },
    async getUsersMatchingUsersScopes(
        requestPayload: IGetUsersMatchingUsersScopesRequest,
    ): Promise<IUserMatchItem[]> {
        const { data } = await baseApi.post<IGetUsersMatchingUsersScopesRequest, IUserMatchResponse>(
            `/${clientConfigurationsService}/${scopesRoute}/matched_users_pairs`,
            requestPayload,
        );
        return data?.user_matches ?? [];
    },
    async getMatchingScopesForActivitiesV2(
        requestPayload: IGetScopesV2Request,
    ): Promise<IScope[]> {
        const { data } = await baseApi.post<IGetScopesV2Request, IScopesActivitiesV2Result>(
            `/${clientConfigurationsService}-additional/${scopesRoute}/activities/simplified`,
            requestPayload,
        );
        return data?.scopes ?? [];
    },
};
