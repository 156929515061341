import { createRequestActions, RequestType } from 'store/utils';
import {
    CustomFieldScopeRestrictions,
    CustomFieldScopeRestrictionsByAction,
    IGetScopeCustomFieldRestrictionRequest,
    IGetScopeRequest, IGetScopesV2Request, IGetUsersMatchingUsersScopesRequest,
    IGroupedScopeCustomFieldValuesRequest,
    IScope, IUserMatchItemResult,
} from 'store/entities/scopes/models';

export const getScopes = createRequestActions<IGetScopeRequest, Array<IScope>>(
    RequestType.Get,
    'SCOPES',
);

export const getScopesLightweight = createRequestActions<IGetScopeRequest, Array<IScope>>(
    RequestType.Get,
    'SCOPES_LIGHTWEIGHT',
);

export const getScopeFull = createRequestActions<string, IScope[]>(
    RequestType.Get,
    'SCOPE_FULL',
);

export const getScopesV2 = createRequestActions<IGetScopesV2Request, Array<IScope>>(
    RequestType.Get,
    'SCOPES_V2',
);

export const getUserCustomFieldRestrictions = createRequestActions<
IGetScopeCustomFieldRestrictionRequest, CustomFieldScopeRestrictions>(
    RequestType.Get,
    'USER_SCOPE_RESTRICTION',
);

//TODO: Combine with v1 after enable feature
export const getUserCustomFieldRestrictionsV2 = createRequestActions<
IGroupedScopeCustomFieldValuesRequest, CustomFieldScopeRestrictionsByAction>(
    RequestType.Get,
    'USER_SCOPE_RESTRICTION_V2',
);

export const deleteScopes = createRequestActions<string, string>(
    RequestType.Delete,
    'SCOPES',
);

export const getUsersMatchingScopes = createRequestActions<
IGetUsersMatchingUsersScopesRequest, IUserMatchItemResult[]>(
    RequestType.Get,
    'SCOPE_USER_PAIR_MATCHES',
);
