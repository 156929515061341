import { combineReducers } from 'redux';
import {
    deleteScopes, getScopeFull,
    getScopes, getScopesLightweight, getScopesV2,
    getUserCustomFieldRestrictions,
    getUserCustomFieldRestrictionsV2,
    getUsersMatchingScopes,
} from 'store/entities/scopes/actions';
import { IScope, IUserMatchItemResult } from 'store/entities/scopes/models';
import { deleteItemByIdReducer, isLoadingReducer, itemsByIdReducer, singleValueReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';

const fullScopes = itemsByIdReducer<IScope, IScope>(getScopeFull);

const scopesByIds = extendReducer(
    itemsByIdReducer<IScope, IScope>(getScopesV2),
    itemsByIdReducer<IScope, IScope>(getScopesLightweight),
    itemsByIdReducer<IScope, IScope>(getScopeFull),
    deleteItemByIdReducer<IScope>(deleteScopes),
);
const isLoading = isLoadingReducer(getScopes);
const isLoadingV2 = isLoadingReducer(getScopesV2);
const isLoadingRestrictions = isLoadingReducer(getUserCustomFieldRestrictions);
const userCustomFieldRestrictions = singleValueReducer(getUserCustomFieldRestrictions.successType, {});
const userCustomFieldRestrictionsByActions = singleValueReducer(
    getUserCustomFieldRestrictionsV2.successType,
    {},
);
const usersMatches = itemsByIdReducer<IUserMatchItemResult, IUserMatchItemResult>(getUsersMatchingScopes);

export const scopes = combineReducers({
    isLoadingV2,
    fullScopes,
    scopesByIds,
    isLoadingRestrictions,
    isLoading,
    userCustomFieldRestrictions,
    userCustomFieldRestrictionsByActions,
    usersMatches,
});
