import { orderBy } from 'lodash-es';
import { IInfoItemData } from 'modules/subassignmentManagement/components/AssignmentInfo/EditableInfoBlock/model';
import { selectDetailAssignmentId } from 'modules/subassignmentManagement/components/AssignmentInfo/store/selectors';
import { SubassignmentInfoLabel } from 'modules/subassignmentManagement/components/EditSubAssignment/EditSingleSubassignmentModal/store/model';
import { selectEditSubAssignmentState } from 'modules/subassignmentManagement/components/EditSubAssignment/store/selectors';
import { selectEmployeeProfilesByIds } from 'modules/offerLetter/store/selectors';
import { createSelector } from 'reselect';
import { getUserName } from 'shared/utils/converters/user';
import { formatPayRate, formatSubassignmentPayRate } from 'shared/utils/formatters/payRate';
import { IStore } from 'store/configureStore';
import { IAssignment, ISubassignment } from 'store/entities/configuration/configurationModel';
import { selectAssignmentsById, selectSubassignmentsByIds } from 'store/entities/configuration/configurationSelectors';
import {
    selectCustomFieldsByIds,
    selectCustomFieldsOrderByIds,
    selectCustomFieldValuesByIds,
} from 'store/entities/customFields/selectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { getAssignmentsGroupedCustomFieldDisplayValues } from 'store/entities/customFields/utils';
import { selectClientPaySettings } from 'store/entities/clients/selectors/timeAndPaySelectors';

export const selectEditSubassignmentId = (store: IStore) =>
    selectEditSubAssignmentState(store).editSingleSubassignment.editId;

export const selectIsUpdatingSubassignment = (store: IStore) =>
    selectEditSubAssignmentState(store).editSingleSubassignment.isLoading;

export const selectEditSubassignment = createSelector(
    selectEditSubassignmentId,
    selectSubassignmentsByIds,
    (subassignmentId, subassignmentsByIds): ISubassignment | undefined => {
        return subassignmentsByIds[subassignmentId];
    },
);

export const selectAssignmentByEditSubassignment = createSelector(
    selectDetailAssignmentId,
    selectEditSubassignment,
    selectAssignmentsById,
    (assignmentId, subassignment, assignmentByIds): IAssignment | undefined => {
        return assignmentByIds[subassignment?.assignment_id || assignmentId];
    },
);

export const selectSubassignmentInfo = createSelector(
    selectEditSubassignmentId,
    selectSubassignmentsByIds,
    selectAssignmentsById,
    selectUsersById,
    selectCustomFieldsByIds,
    selectCustomFieldValuesByIds,
    selectEmployeeProfilesByIds,
    selectClientPaySettings,
    selectCustomFieldsOrderByIds,
    (
        subassignmentId,
        subassignmentsByIds,
        assignmentByIds,
        usersByIds,
        customFieldsByIds,
        customFieldValuesByIds,
        employeeProfilesByIds,
        paySettings,
        orderMap: Record<string, number>,
    ): IInfoItemData[] => {
        const subassignment = subassignmentsByIds[subassignmentId];
        const assignment = assignmentByIds[subassignment?.assignment_id];
        const employee = usersByIds[assignment?.user_id || ''];
        const employeeProfile = employeeProfilesByIds[assignment?.employee_profile?.id || ''];

        const groupedCustomFieldValues: 
        Record<string, string[]> = subassignment ? getAssignmentsGroupedCustomFieldDisplayValues(
            subassignment?.custom_field_value_ids || [],
            [
                ...(subassignment?.all_parent_custom_field_ids || []),
                ...(subassignment?.all_values_custom_field_ids || []),
            ],
            customFieldValuesByIds,
        ) : {};

        const customFieldBlocks = Object.entries(groupedCustomFieldValues).map(([fieldId, fieldValues]) => {
            const value = fieldValues.filter(Boolean).join(', ');
            const field = customFieldsByIds[fieldId];
            const order = orderMap[field?.id || ''] || 1; // one is the min value from backend
            return {
                label: field?.name,
                value,
                order,
                additionalData: {
                    customFieldType: field?.headway_connect_field?.key,
                },
            };
        }) || [];

        const subassignmentInfo = [
            {
                label: SubassignmentInfoLabel.Employee,
                value: `${getUserName(employee)} ${(employeeProfile.avionte_talent_id
                    ? employeeProfile.avionte_talent_id : employeeProfile?.prism_employee_id) || ''}`,
                order: -3, // Hardcoded values should go first
            },
            {
                label: SubassignmentInfoLabel.Placement,
                value: subassignment?.avionte_placement_id ? subassignment?.avionte_placement_id : '',
                order: -2,
            },
            {
                label: SubassignmentInfoLabel.BaseRate,
                value: assignment ? formatPayRate(assignment, paySettings?.pay_period_type) : '',
                order: -1,
            },
            {
                label: SubassignmentInfoLabel.PayRate,
                value: (
                    assignment && subassignment
                        ? formatSubassignmentPayRate(
                            assignment,
                            subassignment,
                            paySettings?.pay_period_type,
                        )
                        : ''
                ),
                order: 0,
            },
            ...customFieldBlocks,
        ];

        return orderBy(subassignmentInfo, ['order'], ['asc']);
    },
);
