import React from 'react';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IBulkUpdateV2UpdateChangeSchema, ISubAssignmentBulkActionV2ReportSchema, ISubAssignmentCreateV2ReportSchema } from 'modules/subassignmentManagement/store/models';
import { IBulkEditReportRow } from 'modules/subassignmentManagement/components/PreAndPostInitializeReports/BulkReportV2/models';
import { colors } from 'shared/styles/constants';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';

const getChangesText = (
    changes: IBulkUpdateV2UpdateChangeSchema[],
    key: keyof IBulkUpdateV2UpdateChangeSchema,
): string => {
    return changes.map(item => item[key]).filter(Boolean).join('; ');
};

const concatValues = (values: string[]): string => {
    return values.join(', ');
};

const cellDictionary = {
    employee: {
        key: 'employee',
        title: 'employee',
        render: function EmployeeCell({ employee, className }: ICellProps<ISubAssignmentBulkActionV2ReportSchema>){
            return (
                <PlainText className={className} value={employee} />
            );
        },
    },
    location: {
        key: 'location',
        title: 'location',
        render: function LocationCell({ location, className }: ICellProps<ISubAssignmentBulkActionV2ReportSchema>){
            return (
                <PlainText className={className} value={location} />
            );
        },
    },
    position: {
        key: 'position',
        title: 'Job Order',
        render: function PositionCell({ job_order_id, className }: ICellProps<ISubAssignmentBulkActionV2ReportSchema>){
            return (
                <PlainText className={className} value={job_order_id} />
            );
        },
    },
    payRate: {
        key: 'pay_rate',
        title: 'pay rate',
        render: function PayRateCell({ pay_rate, className }: ICellProps<ISubAssignmentBulkActionV2ReportSchema>){
            return (
                <PlainText className={className} value={formatDollars(pay_rate)} />
            );
        },
    },
    original: {
        key: 'original',
        title: 'original',
        render: function OriginalCell({ changes, className }: ICellProps<IBulkEditReportRow>){
            return (
                <PlainText className={className} value={getChangesText(changes, 'original')} />
            );
        },
    },
    new: {
        key: 'new',
        title: 'new',
        render: function NewCell({ changes, className }: ICellProps<IBulkEditReportRow>){
            return (
                <PlainText className={className} value={getChangesText(changes, 'new')} />
            );
        },
    },
    error: {
        key: 'error',
        title: 'error',
        render: function ErrorCell({ changes, className }: ICellProps<IBulkEditReportRow>){
            return (
                <PlainText className={className} value={getChangesText(changes, 'error')} />
            );
        },
    },
    status: {
        key: 'status',
        title: '',
        width: '50px',
        render: function StatusCell({ failed, className }: ICellProps<ISubAssignmentBulkActionV2ReportSchema>){
            return (
                <Box className={className}>
                    {
                        !failed
                            ? (
                                <CheckIcon component="svg" htmlColor={colors.green} />
                            )
                            : (
                                <CloseIcon component="svg" htmlColor={colors.red} />
                            )
                    }
                </Box>
            );
        },
    },
    originalEndDate: {
        key: 'original',
        title: 'original end date',
        render: function OriginalCell({ changes, className }: ICellProps<IBulkEditReportRow>){
            return (
                <PlainText className={className} value={getChangesText(changes, 'original')} />
            );
        },
    },
    originalStartDate: {
        key: 'new',
        title: 'new end date',
        render: function NewCell({ changes, className }: ICellProps<IBulkEditReportRow>){
            return (
                <PlainText className={className} value={getChangesText(changes, 'new')} />
            );
        },
    },
    managers: {
        key: 'managers',
        title: 'Manager Approver',
        render: function ManagersCell({ managers, className }: ICellProps<ISubAssignmentCreateV2ReportSchema>){
            return (
                <PlainText className={className} value={concatValues(managers)} />
            );
        },
    },
    errors: {
        key: 'errors',
        title: 'Error message',
        render: function ErrorsCell({ errors, className }: ICellProps<ISubAssignmentCreateV2ReportSchema>){
            return (
                <PlainText className={className} value={concatValues(errors)} />
            );
        },
    },
    department: {
        key: 'department',
        title: 'Department',
        render: function DepartmentCell({ department, className }: ICellProps<ISubAssignmentCreateV2ReportSchema>){
            return (
                <PlainText className={className} value={department} />
            );
        },
    },
    startDate: {
        key: 'startDate',
        title: 'Start Date',
        render: function DepartmentCell({ start_date, className }: ICellProps<ISubAssignmentCreateV2ReportSchema>){
            return (
                <PlainText className={className} value={formatShortDate(start_date)} />
            );
        },
    },
};

export const getBulkEditReportV2Cells = () => {
    return [
        cellDictionary.employee,
        cellDictionary.location,
        cellDictionary.position,
        cellDictionary.payRate,
        cellDictionary.original,
        cellDictionary.new,
        cellDictionary.error,
        cellDictionary.status,
    ];
};

export const getBulkEndReportCells = () => {
    return [
        cellDictionary.employee,
        cellDictionary.location,
        cellDictionary.position,
        cellDictionary.payRate,
        cellDictionary.originalEndDate,
        cellDictionary.originalStartDate,
        cellDictionary.error,
        cellDictionary.status,
    ];
};

export const getBulkCreationReportCells = () => {
    return [
        cellDictionary.employee,
        cellDictionary.payRate,
        cellDictionary.position,
        cellDictionary.managers,
        cellDictionary.startDate,
        cellDictionary.errors,
        cellDictionary.status,
    ];
};
