import { groupBy } from 'lodash-es';
import { ItemsById } from 'shared/models/ItemsById';
import { ICustomFieldValue } from 'store/entities/customFields/model';

export const getFieldValueId = (fieldValue: ICustomFieldValue) => fieldValue?.id;
export const getFieldValueName = (fieldValue?: ICustomFieldValue) => fieldValue?.data?.name || '';

export const getAssignmentsFieldValueName = (fieldValue?: ICustomFieldValue) =>
    (fieldValue?.data as any)?.job_id
        ? `${(fieldValue?.data as any)?.job_id} - ${fieldValue?.data?.name ?? ''}`
        : `${fieldValue?.data?.name ?? ''}`;

export const getCustomFieldValueGroupedByCustomFieldId = (
    customFieldValueIds: string[],
    customFieldValuesByIds: ItemsById<ICustomFieldValue>,
    fieldCallback = getFieldValueId,
) => {
    return Object.entries(
        groupBy(
            customFieldValueIds.map((id: string) => customFieldValuesByIds[id]),
            'custom_field_id',
        ),
    ).reduce((result, [fieldId, fieldValues]) => {
        return {
            ...result,
            [fieldId]: fieldValues.map(fieldCallback),
        };
    }, {});
};
