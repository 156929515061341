/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    createCustomFieldValue,
    setCreateCustomFieldValueModalState,
    setEditCustomFieldValueId,
    updateCustomFieldValue,
} from 'modules/settings/submodules/clients/customFieldValues/store/actions';
import { customFieldValuesApi } from 'modules/settings/submodules/clients/customFieldValues/store/api';
import { selectCustomFieldRelations } from 'modules/settings/submodules/clients/customFieldValues/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    deleteCustomFieldValue,
    queryCustomFieldValues,
} from 'store/entities/customFields/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { uniq } from 'lodash-es';

function* createCustomFieldValueSaga({
    payload,
}: ReturnType<typeof createCustomFieldValue.init>) {
    const clientId = yield select(selectCurrentClientId);
    if (!clientId) {
        throw new Error('Client not specified');
    }
    const fieldValue = yield* call(customFieldValuesApi.createCustomFieldValue, clientId, payload);
    yield put(createCustomFieldValue.success(fieldValue));
    yield put(queryCustomFieldValues.success([fieldValue]));
    yield put(setCreateCustomFieldValueModalState(false));
}

function* createCustomFieldValueWatcher() {
    yield* takeLatest(
        createCustomFieldValue.initType,
        withBackendErrorHandler(
            createCustomFieldValueSaga,
            createCustomFieldValue.error,
            'Unable to create custom field value',
        ),
    );
}

function* updateCustomFieldValueSaga({
    payload,
}: ReturnType<typeof updateCustomFieldValue.init>) {
    const clientId = yield select(selectCurrentClientId);
    if (!clientId) {
        throw new Error('Client not specified');
    }
    const fieldValue = yield* call(customFieldValuesApi.updateCustomFieldValue, clientId, payload.id, payload.data);
    yield put(updateCustomFieldValue.success(fieldValue));

    //Update children
    const relations = yield select(selectCustomFieldRelations);
    const childrenIds = uniq([
        ...(relations.assignment?.childrenFieldIds || []),
        ...(relations.offerLetter?.childrenFieldIds || []),
    ]);
    for (const childrenFieldId of childrenIds) {
        yield put(queryCustomFieldValues.init({ custom_field_id: childrenFieldId, client_id: clientId }));
    }

    yield put(queryCustomFieldValues.success([fieldValue]));
    yield put(setEditCustomFieldValueId(null));
}

function* updateCustomFieldValueWatcher() {
    yield* takeLatest(
        updateCustomFieldValue.initType,
        withBackendErrorHandler(
            updateCustomFieldValueSaga,
            updateCustomFieldValue.error,
            'Unable to update custom field value',
        ),
    );
}

function* deleteCustomFieldValueSaga(
    { payload: id }: ReturnType<typeof deleteCustomFieldValue.init>,
) {
    const clientId = yield select(selectCurrentClientId);
    if (!clientId) {
        throw new Error('Client not specified');
    }
    yield* call(customFieldValuesApi.deleteCustomFieldValue, clientId, id);
    yield put(deleteCustomFieldValue.success(id));
}

function* deleteCustomFieldValueWatcher() {
    yield* takeLatest(
        deleteCustomFieldValue.initType,
        withBackendErrorHandler(
            deleteCustomFieldValueSaga,
            deleteCustomFieldValue.error,
            'Unable to delete Custom Field Value',
        ),
    );
}

export default [
    createCustomFieldValueWatcher,
    updateCustomFieldValueWatcher,
    deleteCustomFieldValueWatcher,
];
